import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024 1024" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="" >
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M655.000000,1025.000000 
	C436.666656,1025.000000 218.833328,1025.000000 1.000000,1025.000000 
	C1.000000,683.666687 1.000000,342.333344 1.000000,1.000000 
	C342.333344,1.000000 683.666687,1.000000 1025.000000,1.000000 
	C1025.000000,342.333344 1025.000000,683.666687 1025.000000,1025.000000 
	C901.833313,1025.000000 778.666687,1025.000000 655.000000,1025.000000 
M478.961823,509.974609 
	C480.241211,507.715759 481.520569,505.456909 483.106415,502.656891 
	C481.091614,502.340454 480.453644,502.150146 479.816589,502.153107 
	C444.864532,502.316376 409.912598,502.538788 374.960388,502.604553 
	C371.591888,502.610870 371.076416,504.270813 371.077759,506.883820 
	C371.082825,516.702759 371.038818,526.522034 371.114624,536.340332 
	C371.126740,537.906433 371.658295,539.468628 371.999573,541.300354 
	C403.580963,541.300354 434.681610,541.318420 465.782166,541.283447 
	C471.311798,541.277222 470.975067,537.363525 470.953278,532.789185 
	C473.407715,525.265076 475.862152,517.740906 478.961823,509.974609 
M371.373901,350.228241 
	C371.202393,352.192749 370.886658,354.156921 370.882050,356.121826 
	C370.842499,372.911865 370.859375,389.702026 370.859375,407.604279 
	C422.876251,408.113098 474.302795,408.724792 525.484436,407.015015 
	C525.484436,387.157776 525.484436,368.260864 525.484436,348.120972 
	C517.847717,348.120972 510.717712,348.109955 503.587769,348.122772 
	C461.798676,348.197937 420.009521,348.259216 378.220734,348.421112 
	C376.347931,348.428345 374.478699,349.365387 372.421967,349.878540 
	C372.421967,349.878540 372.264496,349.977722 371.373901,350.228241 
M438.500000,637.151917 
	C457.249481,637.151917 475.998993,637.151917 495.908386,637.151917 
	C494.331757,634.476196 493.446930,632.972229 492.559937,631.469543 
	C487.084900,622.193481 481.368378,613.047607 476.262970,603.572449 
	C474.442169,600.193176 472.740295,598.854553 468.785553,598.870544 
	C432.956177,599.014893 397.125641,598.928894 361.295746,598.819214 
	C357.317078,598.807068 353.297150,598.491943 349.370697,597.857361 
	C339.116302,596.200195 335.233765,591.810730 334.534271,581.218933 
	C334.095764,574.579651 333.701874,567.922607 333.685242,561.272583 
	C333.610229,531.275513 333.653870,501.278015 333.703033,471.280762 
	C333.725983,457.283478 333.916260,443.286499 333.929565,429.289276 
	C333.956787,400.633026 333.914398,371.976715 333.903290,343.320435 
	C333.899261,332.876617 333.902679,322.432800 333.902679,311.852478 
	C410.342194,311.852478 486.400421,311.852478 563.181152,311.852478 
	C563.181152,356.936005 563.181152,401.652100 563.181152,445.712921 
	C575.183167,445.712921 586.450928,445.586334 597.713074,445.780304 
	C601.759033,445.849976 602.738159,444.292084 602.697144,440.454651 
	C602.528015,424.624878 602.700989,408.791687 602.669067,392.959930 
	C602.663940,390.421417 602.754395,388.074921 606.019958,388.055084 
	C617.259827,387.986816 628.500305,388.028839 640.690308,388.028839 
	C640.690308,407.729736 640.690308,426.286163 640.690308,444.748535 
	C648.469727,445.110931 655.281433,445.526428 662.099304,445.708466 
	C666.082031,445.814819 670.080383,445.648743 674.061035,445.420074 
	C677.723999,445.209625 679.049744,443.171112 679.034424,439.448242 
	C678.927917,413.455963 679.031250,387.462891 678.969299,361.470337 
	C678.959900,357.522400 678.442505,353.575684 678.138550,349.327820 
	C652.426331,347.974854 627.159424,352.147217 601.173462,348.600189 
	C601.173462,323.071564 601.173462,298.000732 601.173462,273.081451 
	C498.836761,273.081451 397.386108,273.081451 295.527802,273.081451 
	C295.374847,275.819733 295.120056,278.276764 295.117554,280.734070 
	C295.056763,341.061798 295.008026,401.389526 294.989380,461.717285 
	C294.977692,499.547150 294.988403,537.377075 295.048218,575.206909 
	C295.078552,594.395691 300.751526,611.112915 316.116730,623.755859 
	C327.924103,633.471252 341.729095,636.731812 356.508057,636.852539 
	C383.504364,637.072937 410.502594,637.062683 438.500000,637.151917 
M666.721802,606.195496 
	C677.088135,586.787842 680.620239,566.282410 677.151184,544.479614 
	C674.330017,526.748047 667.019714,511.277344 655.234802,497.796814 
	C650.569885,492.460693 650.615784,491.876678 655.651611,486.786652 
	C659.984558,482.407104 664.614807,478.323029 668.992493,473.986084 
	C671.457092,471.544403 673.676697,468.855347 676.820374,465.377502 
	C673.768921,465.377502 672.290405,465.380371 670.811890,465.377106 
	C647.315430,465.325195 623.818787,465.312103 600.322754,465.184418 
	C595.966736,465.160767 596.166260,467.932648 596.171692,470.902161 
	C596.214111,493.898743 596.217529,516.895386 596.258301,539.892029 
	C596.260742,541.263245 596.480957,542.634094 596.599854,544.005066 
	C597.058044,544.250732 597.516235,544.496399 597.974426,544.742126 
	C606.267578,536.500793 614.560669,528.259521 622.739075,520.132263 
	C639.040588,531.339905 647.137878,565.929199 632.241028,589.921387 
	C616.271362,615.641479 580.921143,625.379395 554.762634,610.826904 
	C541.199707,603.281555 532.023376,591.818848 527.609375,577.062805 
	C521.170471,555.537354 526.678162,536.430054 541.727600,519.469543 
	C535.141663,512.797180 529.016357,506.469543 522.755981,500.278503 
	C514.145325,491.763336 513.513916,491.738129 506.519531,501.654480 
	C490.372681,524.546814 483.864136,549.615723 488.854309,577.598877 
	C496.323120,619.481201 530.264526,653.164062 576.281128,655.739929 
	C615.810669,657.952637 645.956238,640.883362 666.721802,606.195496 
M487.286438,483.412048 
	C494.059540,484.205536 499.319214,482.085968 503.917969,476.688477 
	C507.412384,472.587036 512.093872,469.379486 516.593628,466.276672 
	C525.758789,459.956696 527.215759,456.434082 524.125061,445.250427 
	C523.073303,445.250427 521.927917,445.251801 520.782471,445.250214 
	C472.983704,445.184753 425.184937,445.118774 377.386169,445.054932 
	C376.553711,445.053833 375.486816,444.754608 374.935791,445.154572 
	C373.651855,446.086395 371.653961,447.339508 371.628967,448.498779 
	C371.382416,459.935669 371.479950,471.379974 371.479950,483.419617 
	C410.448578,483.419617 448.391724,483.419617 487.286438,483.412048 
M294.414673,722.094910 
	C301.024658,732.084656 307.594543,742.101501 314.295837,752.029663 
	C315.126190,753.259888 316.612762,754.793518 317.867889,754.862305 
	C323.467499,755.169495 329.093475,754.995728 334.870056,754.995728 
	C334.870056,728.572815 334.870056,702.665405 334.870056,676.591675 
	C328.584656,676.591675 322.552185,676.591675 316.014038,676.591675 
	C316.014038,692.478638 316.014038,707.986755 316.014038,724.676331 
	C314.467743,722.443970 313.621643,721.273071 312.827271,720.067932 
	C303.945496,706.592957 295.117310,693.082092 286.130676,679.677490 
	C285.148499,678.212463 283.331238,676.481140 281.789978,676.366211 
	C276.369171,675.961914 270.899780,676.209167 265.370972,676.209167 
	C265.370972,702.778015 265.370972,728.806763 265.370972,754.763062 
	C271.689941,754.763062 277.631073,754.763062 284.213379,754.763062 
	C284.213379,738.952332 284.213379,723.371521 284.213379,706.555420 
	C287.991394,712.320984 290.998535,716.910156 294.414673,722.094910 
M508.450745,676.981812 
	C496.049286,676.981812 483.647797,676.981812 471.278381,676.981812 
	C471.278381,702.837158 471.278381,728.743286 471.278381,754.808838 
	C482.847076,754.808838 494.200592,755.592041 505.409607,754.652100 
	C531.911621,752.429810 547.198730,729.748352 540.069702,704.042480 
	C535.731995,688.401306 524.762817,680.108704 508.450745,676.981812 
M698.817383,708.182800 
	C693.214905,723.620972 687.612366,739.059082 681.893433,754.818237 
	C688.816284,754.818237 695.248718,754.818237 701.841492,754.818237 
	C703.088928,751.136169 704.147949,747.446106 705.633179,743.936401 
	C706.143799,742.729858 707.698547,741.167664 708.811096,741.139465 
	C717.779968,740.912048 726.757202,741.011963 735.802795,741.011963 
	C737.154724,744.906616 738.237671,748.582886 739.748230,752.074219 
	C740.272644,753.286438 741.817932,754.816162 742.962646,754.872864 
	C748.726807,755.157715 754.512634,755.003601 760.605164,755.003601 
	C760.204407,753.533875 760.011658,752.560669 759.677063,751.638977 
	C752.533325,731.962280 745.405518,712.279663 738.196167,692.627014 
	C736.206482,687.203125 735.392090,679.784119 731.439514,677.081177 
	C727.431824,674.340637 720.198364,676.482788 714.399048,676.132874 
	C711.100647,675.933899 709.832458,677.270630 708.827209,680.251953 
	C705.750916,689.375366 702.314514,698.377258 698.817383,708.182800 
M603.903076,755.005859 
	C609.363037,755.005859 614.822937,755.005859 620.568481,755.005859 
	C620.202820,753.594849 620.032227,752.615601 619.698364,751.695679 
	C612.558594,732.026428 605.428894,712.353455 598.227661,692.706665 
	C596.238220,687.278992 595.467468,679.724609 591.523438,677.149414 
	C587.282654,674.380249 579.959351,676.472290 573.998291,676.137024 
	C571.120789,675.975098 569.922241,677.099548 569.011719,679.678955 
	C564.202332,693.302979 559.216125,706.864502 554.308655,720.454041 
	C550.202026,731.825928 546.119385,743.206421 541.941956,754.816956 
	C548.839233,754.816956 555.280273,754.816956 561.853149,754.816956 
	C563.059204,751.224304 564.347412,747.796021 565.341675,744.284485 
	C566.032410,741.844849 567.209351,740.827271 569.879089,740.941956 
	C575.033264,741.163452 580.203857,741.006104 585.367615,741.006042 
	C595.701538,741.005981 595.600830,741.043396 599.201050,750.744690 
	C599.817810,752.406616 601.706665,753.596375 603.903076,755.005859 
M372.212341,720.709229 
	C376.877289,721.115967 380.822266,722.743530 381.807648,727.830933 
	C382.556580,731.697571 382.274384,735.620789 378.547150,738.139771 
	C374.694275,740.743591 370.277985,740.803833 366.875763,738.056030 
	C364.273834,735.954468 362.890076,732.344604 360.917267,729.333557 
	C355.572235,729.333557 349.813385,729.333557 343.748840,729.333557 
	C344.022186,731.671753 344.097717,733.671326 344.506592,735.600220 
	C347.703644,750.682800 360.409821,756.059021 375.365875,755.642883 
	C383.226135,755.424255 390.532623,753.129456 395.685791,746.622437 
	C404.752441,735.173767 401.440430,720.883911 387.045471,711.697998 
	C387.741302,711.448242 388.253418,711.334167 388.697754,711.094543 
	C397.098053,706.563904 400.192261,699.268799 398.864807,690.179138 
	C397.593506,681.473755 392.264343,675.770935 383.969971,673.098511 
	C375.810791,670.469727 367.567413,670.587708 359.522675,673.691040 
	C350.017303,677.357849 344.224182,686.598755 345.537567,696.118713 
	C350.119507,696.118713 354.765106,695.974487 359.395966,696.173340 
	C362.130310,696.290771 363.470001,695.602661 364.327240,692.713257 
	C365.667572,688.195740 369.396637,686.889893 373.853516,687.517090 
	C378.188568,688.127197 380.136932,690.929871 380.478882,695.031250 
	C380.861725,699.622375 378.998169,702.891785 374.361725,703.935913 
	C371.172302,704.654175 367.875244,704.894470 364.562744,705.357239 
	C364.562744,710.343872 364.562744,715.222595 364.562744,719.984741 
	C365.229950,720.204651 365.537445,720.385254 365.850891,720.396301 
	C367.680969,720.460815 369.512482,720.485474 372.212341,720.709229 
M694.053833,257.443390 
	C676.075989,239.332092 653.653503,233.457779 628.993652,234.097488 
	C623.973877,234.227722 619.112366,235.675247 615.999817,240.297470 
	C612.324341,245.755600 614.627380,250.661850 621.121460,251.067535 
	C626.604858,251.410065 632.105347,251.512207 637.581177,251.939362 
	C663.976807,253.998352 683.265320,266.781647 694.801331,290.662964 
	C700.732422,302.941040 701.480042,316.229034 701.022705,329.636505 
	C700.906189,333.052063 701.299988,336.774689 705.192688,337.808502 
	C709.378357,338.920074 713.496826,337.529053 715.434204,333.518555 
	C717.196777,329.869781 718.806885,325.690277 718.832275,321.734161 
	C718.987976,297.413147 712.140991,275.591522 694.053833,257.443390 
M459.333038,691.803955 
	C461.254089,691.803955 463.175110,691.803955 465.088989,691.803955 
	C465.088989,686.212646 465.088989,681.324646 465.088989,676.461731 
	C444.922699,676.461731 425.159790,676.461731 405.198608,676.461731 
	C405.198608,681.610535 405.198608,686.491333 405.198608,691.956299 
	C412.079041,691.956299 418.655182,691.956299 425.704376,691.956299 
	C425.704376,713.277527 425.704376,734.015747 425.704376,754.672607 
	C432.293060,754.672607 438.370331,754.672607 444.955933,754.672607 
	C444.955933,733.615417 444.955933,712.891785 444.955933,691.803894 
	C449.771149,691.803894 454.072845,691.803894 459.333038,691.803955 
M649.500000,676.195984 
	C640.399719,676.195984 631.299377,676.195984 622.289551,676.195984 
	C622.289551,681.765137 622.289551,686.646179 622.289551,691.947021 
	C629.130066,691.947021 635.561890,691.947021 642.495300,691.947021 
	C642.495300,713.110840 642.495300,733.849243 642.495300,754.590088 
	C649.209351,754.590088 655.400574,754.590088 662.099121,754.590088 
	C662.099121,733.454529 662.099121,712.737305 662.099121,691.672852 
	C669.058716,691.672852 675.501221,691.672852 682.184326,691.672852 
	C682.184326,686.410095 682.184326,681.506287 682.184326,676.196106 
	C671.421875,676.196106 660.960938,676.196106 649.500000,676.195984 
M612.521118,278.917664 
	C615.237793,279.929779 617.880737,281.382050 620.688171,281.852631 
	C625.087646,282.590027 629.639160,282.395111 634.054260,283.067871 
	C649.672546,285.447815 660.934448,293.500153 667.077393,308.437775 
	C670.384338,316.479034 671.147156,324.716461 671.124451,333.278412 
	C671.117798,335.788971 672.827393,339.657928 674.797241,340.524414 
	C678.365051,342.093750 682.453674,340.733948 684.838013,337.156647 
	C685.983643,335.437775 686.788025,333.208740 686.938599,331.151550 
	C689.256592,299.477814 673.034729,272.516907 640.259216,266.625519 
	C633.685486,265.443909 626.685181,265.470764 620.029724,266.233368 
	C613.276062,267.007233 610.174500,272.622864 612.521118,278.917664 
M631.706360,314.533691 
	C634.466125,317.956238 638.019836,321.022766 639.759521,324.902618 
	C641.456726,328.687897 641.448486,333.288025 641.919250,337.560089 
	C642.484314,342.686340 644.993530,344.819519 649.832703,343.896179 
	C654.584656,342.989441 657.508423,339.788269 657.837585,333.851410 
	C659.071960,311.586243 644.169434,295.062927 622.890991,294.373230 
	C618.970642,294.246155 614.406067,296.219940 611.205017,298.636383 
	C609.412231,299.989685 609.184448,304.312164 609.501099,307.157745 
	C609.643555,308.438354 612.713684,309.843353 614.677917,310.384796 
	C620.072449,311.871796 625.584839,312.931549 631.706360,314.533691 
M618.382507,346.050354 
	C620.260071,343.635559 622.732544,341.472321 623.883667,338.750305 
	C625.898865,333.985107 624.414490,330.589630 620.487488,328.783875 
	C616.573669,326.984222 610.906006,328.367676 607.852661,331.868042 
	C604.867615,335.290100 603.867676,341.372467 606.119141,344.297852 
	C609.267395,348.388367 613.396362,348.208191 618.382507,346.050354 
z"/>
<path fill="#8205AC" opacity="1.000000" stroke="none" 
	d="
M438.000000,637.150269 
	C410.502594,637.062683 383.504364,637.072937 356.508057,636.852539 
	C341.729095,636.731812 327.924103,633.471252 316.116730,623.755859 
	C300.751526,611.112915 295.078552,594.395691 295.048218,575.206909 
	C294.988403,537.377075 294.977692,499.547150 294.989380,461.717285 
	C295.008026,401.389526 295.056763,341.061798 295.117554,280.734070 
	C295.120056,278.276764 295.374847,275.819733 295.527802,273.081451 
	C397.386108,273.081451 498.836761,273.081451 601.173462,273.081451 
	C601.173462,298.000732 601.173462,323.071564 601.173462,348.600189 
	C627.159424,352.147217 652.426331,347.974854 678.138550,349.327820 
	C678.442505,353.575684 678.959900,357.522400 678.969299,361.470337 
	C679.031250,387.462891 678.927917,413.455963 679.034424,439.448242 
	C679.049744,443.171112 677.723999,445.209625 674.061035,445.420074 
	C670.080383,445.648743 666.082031,445.814819 662.099304,445.708466 
	C655.281433,445.526428 648.469727,445.110931 640.690308,444.748535 
	C640.690308,426.286163 640.690308,407.729736 640.690308,388.028839 
	C628.500305,388.028839 617.259827,387.986816 606.019958,388.055084 
	C602.754395,388.074921 602.663940,390.421417 602.669067,392.959930 
	C602.700989,408.791687 602.528015,424.624878 602.697144,440.454651 
	C602.738159,444.292084 601.759033,445.849976 597.713074,445.780304 
	C586.450928,445.586334 575.183167,445.712921 563.181152,445.712921 
	C563.181152,401.652100 563.181152,356.936005 563.181152,311.852478 
	C486.400421,311.852478 410.342194,311.852478 333.902679,311.852478 
	C333.902679,322.432800 333.899261,332.876617 333.903290,343.320435 
	C333.914398,371.976715 333.956787,400.633026 333.929565,429.289276 
	C333.916260,443.286499 333.725983,457.283478 333.703033,471.280762 
	C333.653870,501.278015 333.610229,531.275513 333.685242,561.272583 
	C333.701874,567.922607 334.095764,574.579651 334.534271,581.218933 
	C335.233765,591.810730 339.116302,596.200195 349.370697,597.857361 
	C353.297150,598.491943 357.317078,598.807068 361.295746,598.819214 
	C397.125641,598.928894 432.956177,599.014893 468.785553,598.870544 
	C472.740295,598.854553 474.442169,600.193176 476.262970,603.572449 
	C481.368378,613.047607 487.084900,622.193481 492.559937,631.469543 
	C493.446930,632.972229 494.331757,634.476196 495.908386,637.151917 
	C475.998993,637.151917 457.249481,637.151917 438.000000,637.150269 
z"/>
<path fill="#F205FF" opacity="1.000000" stroke="none" 
	d="
M666.520630,606.504150 
	C645.956238,640.883362 615.810669,657.952637 576.281128,655.739929 
	C530.264526,653.164062 496.323120,619.481201 488.854309,577.598877 
	C483.864136,549.615723 490.372681,524.546814 506.519531,501.654480 
	C513.513916,491.738129 514.145325,491.763336 522.755981,500.278503 
	C529.016357,506.469543 535.141663,512.797180 541.727600,519.469543 
	C526.678162,536.430054 521.170471,555.537354 527.609375,577.062805 
	C532.023376,591.818848 541.199707,603.281555 554.762634,610.826904 
	C580.921143,625.379395 616.271362,615.641479 632.241028,589.921387 
	C647.137878,565.929199 639.040588,531.339905 622.739075,520.132263 
	C614.560669,528.259521 606.267578,536.500793 597.974426,544.742126 
	C597.516235,544.496399 597.058044,544.250732 596.599854,544.005066 
	C596.480957,542.634094 596.260742,541.263245 596.258301,539.892029 
	C596.217529,516.895386 596.214111,493.898743 596.171692,470.902161 
	C596.166260,467.932648 595.966736,465.160767 600.322754,465.184418 
	C623.818787,465.312103 647.315430,465.325195 670.811890,465.377106 
	C672.290405,465.380371 673.768921,465.377502 676.820374,465.377502 
	C673.676697,468.855347 671.457092,471.544403 668.992493,473.986084 
	C664.614807,478.323029 659.984558,482.407104 655.651611,486.786652 
	C650.615784,491.876678 650.569885,492.460693 655.234802,497.796814 
	C667.019714,511.277344 674.330017,526.748047 677.151184,544.479614 
	C680.620239,566.282410 677.088135,586.787842 666.520630,606.504150 
z"/>
<path fill="#9900CC" opacity="1.000000" stroke="none" 
	d="
M372.264496,349.977722 
	C372.264496,349.977722 372.421967,349.878540 373.001587,349.911804 
	C380.725006,350.258148 387.869904,350.851227 395.012390,350.823669 
	C408.159546,350.772919 421.304230,350.240631 434.451843,350.122162 
	C452.282379,349.961456 470.114685,349.986664 487.946259,349.960693 
	C497.778595,349.946381 507.611176,350.031403 517.443298,349.989990 
	C521.912354,349.971161 524.289185,351.120667 524.140869,356.525757 
	C523.738708,371.180023 524.010376,385.852509 523.989746,400.517761 
	C523.987305,402.269165 523.829895,404.020386 523.750305,405.648193 
	C473.189667,405.648193 423.265991,405.648193 372.264496,405.648193 
	C372.264496,386.853149 372.264496,368.415436 372.264496,349.977722 
z"/>
<path fill="#9A02CD" opacity="1.000000" stroke="none" 
	d="
M486.810669,483.415833 
	C448.391724,483.419617 410.448578,483.419617 371.479950,483.419617 
	C371.479950,471.379974 371.382416,459.935669 371.628967,448.498779 
	C371.653961,447.339508 373.651855,446.086395 374.935791,445.154572 
	C375.486816,444.754608 376.553711,445.053833 377.386169,445.054932 
	C425.184937,445.118774 472.983704,445.184753 520.782471,445.250214 
	C521.927917,445.251801 523.073303,445.250427 524.125061,445.250427 
	C527.215759,456.434082 525.758789,459.956696 516.593628,466.276672 
	C512.093872,469.379486 507.412384,472.587036 503.917969,476.688477 
	C499.319214,482.085968 494.059540,484.205536 486.810669,483.415833 
z"/>
<path fill="#9A02CD" opacity="1.000000" stroke="none" 
	d="
M294.210144,721.797119 
	C290.998535,716.910156 287.991394,712.320984 284.213379,706.555420 
	C284.213379,723.371521 284.213379,738.952332 284.213379,754.763062 
	C277.631073,754.763062 271.689941,754.763062 265.370972,754.763062 
	C265.370972,728.806763 265.370972,702.778015 265.370972,676.209167 
	C270.899780,676.209167 276.369171,675.961914 281.789978,676.366211 
	C283.331238,676.481140 285.148499,678.212463 286.130676,679.677490 
	C295.117310,693.082092 303.945496,706.592957 312.827271,720.067932 
	C313.621643,721.273071 314.467743,722.443970 316.014038,724.676331 
	C316.014038,707.986755 316.014038,692.478638 316.014038,676.591675 
	C322.552185,676.591675 328.584656,676.591675 334.870056,676.591675 
	C334.870056,702.665405 334.870056,728.572815 334.870056,754.995728 
	C329.093475,754.995728 323.467499,755.169495 317.867889,754.862305 
	C316.612762,754.793518 315.126190,753.259888 314.295837,752.029663 
	C307.594543,742.101501 301.024658,732.084656 294.210144,721.797119 
z"/>
<path fill="#9A02CD" opacity="1.000000" stroke="none" 
	d="
M508.881592,676.997253 
	C524.762817,680.108704 535.731995,688.401306 540.069702,704.042480 
	C547.198730,729.748352 531.911621,752.429810 505.409607,754.652100 
	C494.200592,755.592041 482.847076,754.808838 471.278381,754.808838 
	C471.278381,728.743286 471.278381,702.837158 471.278381,676.981812 
	C483.647797,676.981812 496.049286,676.981812 508.881592,676.997253 
M490.000458,715.500000 
	C490.000458,723.084229 490.000458,730.668457 490.000458,738.646179 
	C501.097443,738.541748 512.222168,739.804993 518.694519,729.090637 
	C523.934814,720.415833 523.944763,710.784790 518.612061,702.061890 
	C512.023315,691.284424 501.034882,692.605042 490.000458,692.979675 
	C490.000458,700.294739 490.000458,707.397400 490.000458,715.500000 
z"/>
<path fill="#9900CB" opacity="1.000000" stroke="none" 
	d="
M478.316620,510.216766 
	C475.862152,517.740906 473.407715,525.265076 470.572449,533.365356 
	C469.172333,538.634338 465.916412,539.190979 461.709625,539.170166 
	C433.556732,539.031128 405.402802,539.105957 377.249207,539.085327 
	C375.979401,539.084412 374.709747,538.831482 373.305359,538.681580 
	C373.305359,527.112549 373.305359,515.872925 373.305359,504.339661 
	C408.810699,504.339661 444.019928,504.339661 479.223724,504.339661 
	C478.906036,506.397980 478.611328,508.307373 478.316620,510.216766 
z"/>
<path fill="#9A03CD" opacity="1.000000" stroke="none" 
	d="
M698.917725,707.804626 
	C702.314514,698.377258 705.750916,689.375366 708.827209,680.251953 
	C709.832458,677.270630 711.100647,675.933899 714.399048,676.132874 
	C720.198364,676.482788 727.431824,674.340637 731.439514,677.081177 
	C735.392090,679.784119 736.206482,687.203125 738.196167,692.627014 
	C745.405518,712.279663 752.533325,731.962280 759.677063,751.638977 
	C760.011658,752.560669 760.204407,753.533875 760.605164,755.003601 
	C754.512634,755.003601 748.726807,755.157715 742.962646,754.872864 
	C741.817932,754.816162 740.272644,753.286438 739.748230,752.074219 
	C738.237671,748.582886 737.154724,744.906616 735.802795,741.011963 
	C726.757202,741.011963 717.779968,740.912048 708.811096,741.139465 
	C707.698547,741.167664 706.143799,742.729858 705.633179,743.936401 
	C704.147949,747.446106 703.088928,751.136169 701.841492,754.818237 
	C695.248718,754.818237 688.816284,754.818237 681.893433,754.818237 
	C687.612366,739.059082 693.214905,723.620972 698.917725,707.804626 
M729.606018,721.876587 
	C726.937439,714.136597 724.268921,706.396545 721.174622,697.421875 
	C717.787231,707.612244 714.793823,716.617310 711.717773,725.870850 
	C718.436584,725.870850 724.468018,725.870850 730.678223,725.870850 
	C730.341919,724.540039 730.100525,723.584473 729.606018,721.876587 
z"/>
<path fill="#9A03CD" opacity="1.000000" stroke="none" 
	d="
M603.455444,755.005066 
	C601.706665,753.596375 599.817810,752.406616 599.201050,750.744690 
	C595.600830,741.043396 595.701538,741.005981 585.367615,741.006042 
	C580.203857,741.006104 575.033264,741.163452 569.879089,740.941956 
	C567.209351,740.827271 566.032410,741.844849 565.341675,744.284485 
	C564.347412,747.796021 563.059204,751.224304 561.853149,754.816956 
	C555.280273,754.816956 548.839233,754.816956 541.941956,754.816956 
	C546.119385,743.206421 550.202026,731.825928 554.308655,720.454041 
	C559.216125,706.864502 564.202332,693.302979 569.011719,679.678955 
	C569.922241,677.099548 571.120789,675.975098 573.998291,676.137024 
	C579.959351,676.472290 587.282654,674.380249 591.523438,677.149414 
	C595.467468,679.724609 596.238220,687.278992 598.227661,692.706665 
	C605.428894,712.353455 612.558594,732.026428 619.698364,751.695679 
	C620.032227,752.615601 620.202820,753.594849 620.568481,755.005859 
	C614.822937,755.005859 609.363037,755.005859 603.455444,755.005066 
M573.291992,720.865234 
	C572.852600,722.438110 572.413269,724.010986 571.875183,725.937134 
	C578.291504,725.937134 584.364441,725.937134 590.879700,725.937134 
	C587.739929,716.649841 584.694702,707.642029 581.215698,697.351257 
	C578.423157,705.737976 576.028381,712.930237 573.291992,720.865234 
z"/>
<path fill="#9A03CD" opacity="1.000000" stroke="none" 
	d="
M371.777924,720.616394 
	C369.512482,720.485474 367.680969,720.460815 365.850891,720.396301 
	C365.537445,720.385254 365.229950,720.204651 364.562744,719.984741 
	C364.562744,715.222595 364.562744,710.343872 364.562744,705.357239 
	C367.875244,704.894470 371.172302,704.654175 374.361725,703.935913 
	C378.998169,702.891785 380.861725,699.622375 380.478882,695.031250 
	C380.136932,690.929871 378.188568,688.127197 373.853516,687.517090 
	C369.396637,686.889893 365.667572,688.195740 364.327240,692.713257 
	C363.470001,695.602661 362.130310,696.290771 359.395966,696.173340 
	C354.765106,695.974487 350.119507,696.118713 345.537567,696.118713 
	C344.224182,686.598755 350.017303,677.357849 359.522675,673.691040 
	C367.567413,670.587708 375.810791,670.469727 383.969971,673.098511 
	C392.264343,675.770935 397.593506,681.473755 398.864807,690.179138 
	C400.192261,699.268799 397.098053,706.563904 388.697754,711.094543 
	C388.253418,711.334167 387.741302,711.448242 387.045471,711.697998 
	C401.440430,720.883911 404.752441,735.173767 395.685791,746.622437 
	C390.532623,753.129456 383.226135,755.424255 375.365875,755.642883 
	C360.409821,756.059021 347.703644,750.682800 344.506592,735.600220 
	C344.097717,733.671326 344.022186,731.671753 343.748840,729.333557 
	C349.813385,729.333557 355.572235,729.333557 360.917267,729.333557 
	C362.890076,732.344604 364.273834,735.954468 366.875763,738.056030 
	C370.277985,740.803833 374.694275,740.743591 378.547150,738.139771 
	C382.274384,735.620789 382.556580,731.697571 381.807648,727.830933 
	C380.822266,722.743530 376.877289,721.115967 371.777924,720.616394 
z"/>
<path fill="#F206FF" opacity="1.000000" stroke="none" 
	d="
M694.305664,257.692963 
	C712.140991,275.591522 718.987976,297.413147 718.832275,321.734161 
	C718.806885,325.690277 717.196777,329.869781 715.434204,333.518555 
	C713.496826,337.529053 709.378357,338.920074 705.192688,337.808502 
	C701.299988,336.774689 700.906189,333.052063 701.022705,329.636505 
	C701.480042,316.229034 700.732422,302.941040 694.801331,290.662964 
	C683.265320,266.781647 663.976807,253.998352 637.581177,251.939362 
	C632.105347,251.512207 626.604858,251.410065 621.121460,251.067535 
	C614.627380,250.661850 612.324341,245.755600 615.999817,240.297470 
	C619.112366,235.675247 623.973877,234.227722 628.993652,234.097488 
	C653.653503,233.457779 676.075989,239.332092 694.305664,257.692963 
z"/>
<path fill="#9B06CD" opacity="1.000000" stroke="none" 
	d="
M458.853760,691.803955 
	C454.072845,691.803894 449.771149,691.803894 444.955933,691.803894 
	C444.955933,712.891785 444.955933,733.615417 444.955933,754.672607 
	C438.370331,754.672607 432.293060,754.672607 425.704376,754.672607 
	C425.704376,734.015747 425.704376,713.277527 425.704376,691.956299 
	C418.655182,691.956299 412.079041,691.956299 405.198608,691.956299 
	C405.198608,686.491333 405.198608,681.610535 405.198608,676.461731 
	C425.159790,676.461731 444.922699,676.461731 465.088989,676.461731 
	C465.088989,681.324646 465.088989,686.212646 465.088989,691.803955 
	C463.175110,691.803955 461.254089,691.803955 458.853760,691.803955 
z"/>
<path fill="#9B06CD" opacity="1.000000" stroke="none" 
	d="
M650.000000,676.196045 
	C660.960938,676.196106 671.421875,676.196106 682.184326,676.196106 
	C682.184326,681.506287 682.184326,686.410095 682.184326,691.672852 
	C675.501221,691.672852 669.058716,691.672852 662.099121,691.672852 
	C662.099121,712.737305 662.099121,733.454529 662.099121,754.590088 
	C655.400574,754.590088 649.209351,754.590088 642.495300,754.590088 
	C642.495300,733.849243 642.495300,713.110840 642.495300,691.947021 
	C635.561890,691.947021 629.130066,691.947021 622.289551,691.947021 
	C622.289551,686.646179 622.289551,681.765137 622.289551,676.195984 
	C631.299377,676.195984 640.399719,676.195984 650.000000,676.196045 
z"/>
<path fill="#F206FF" opacity="1.000000" stroke="none" 
	d="
M612.299194,278.600433 
	C610.174500,272.622864 613.276062,267.007233 620.029724,266.233368 
	C626.685181,265.470764 633.685486,265.443909 640.259216,266.625519 
	C673.034729,272.516907 689.256592,299.477814 686.938599,331.151550 
	C686.788025,333.208740 685.983643,335.437775 684.838013,337.156647 
	C682.453674,340.733948 678.365051,342.093750 674.797241,340.524414 
	C672.827393,339.657928 671.117798,335.788971 671.124451,333.278412 
	C671.147156,324.716461 670.384338,316.479034 667.077393,308.437775 
	C660.934448,293.500153 649.672546,285.447815 634.054260,283.067871 
	C629.639160,282.395111 625.087646,282.590027 620.688171,281.852631 
	C617.880737,281.382050 615.237793,279.929779 612.299194,278.600433 
z"/>
<path fill="#F206FF" opacity="1.000000" stroke="none" 
	d="
M631.378418,314.347046 
	C625.584839,312.931549 620.072449,311.871796 614.677917,310.384796 
	C612.713684,309.843353 609.643555,308.438354 609.501099,307.157745 
	C609.184448,304.312164 609.412231,299.989685 611.205017,298.636383 
	C614.406067,296.219940 618.970642,294.246155 622.890991,294.373230 
	C644.169434,295.062927 659.071960,311.586243 657.837585,333.851410 
	C657.508423,339.788269 654.584656,342.989441 649.832703,343.896179 
	C644.993530,344.819519 642.484314,342.686340 641.919250,337.560089 
	C641.448486,333.288025 641.456726,328.687897 639.759521,324.902618 
	C638.019836,321.022766 634.466125,317.956238 631.378418,314.347046 
z"/>
<path fill="#7A78A0" opacity="1.000000" stroke="none" 
	d="
M371.819214,350.102966 
	C372.264496,368.415436 372.264496,386.853149 372.264496,405.648193 
	C423.265991,405.648193 473.189667,405.648193 523.750305,405.648193 
	C523.829895,404.020386 523.987305,402.269165 523.989746,400.517761 
	C524.010376,385.852509 523.738708,371.180023 524.140869,356.525757 
	C524.289185,351.120667 521.912354,349.971161 517.443298,349.989990 
	C507.611176,350.031403 497.778595,349.946381 487.946259,349.960693 
	C470.114685,349.986664 452.282379,349.961456 434.451843,350.122162 
	C421.304230,350.240631 408.159546,350.772919 395.012390,350.823669 
	C387.869904,350.851227 380.725006,350.258148 373.094513,349.907196 
	C374.478699,349.365387 376.347931,348.428345 378.220734,348.421112 
	C420.009521,348.259216 461.798676,348.197937 503.587769,348.122772 
	C510.717712,348.109955 517.847717,348.120972 525.484436,348.120972 
	C525.484436,368.260864 525.484436,387.157776 525.484436,407.015015 
	C474.302795,408.724792 422.876251,408.113098 370.859375,407.604279 
	C370.859375,389.702026 370.842499,372.911865 370.882050,356.121826 
	C370.886658,354.156921 371.202393,352.192749 371.819214,350.102966 
z"/>
<path fill="#4B427C" opacity="1.000000" stroke="none" 
	d="
M478.639221,510.095703 
	C478.611328,508.307373 478.906036,506.397980 479.223724,504.339661 
	C444.019928,504.339661 408.810699,504.339661 373.305359,504.339661 
	C373.305359,515.872925 373.305359,527.112549 373.305359,538.681580 
	C374.709747,538.831482 375.979401,539.084412 377.249207,539.085327 
	C405.402802,539.105957 433.556732,539.031128 461.709625,539.170166 
	C465.916412,539.190979 469.172333,538.634338 470.419922,533.749634 
	C470.975067,537.363525 471.311798,541.277222 465.782166,541.283447 
	C434.681610,541.318420 403.580963,541.300354 371.999573,541.300354 
	C371.658295,539.468628 371.126740,537.906433 371.114624,536.340332 
	C371.038818,526.522034 371.082825,516.702759 371.077759,506.883820 
	C371.076416,504.270813 371.591888,502.610870 374.960388,502.604553 
	C409.912598,502.538788 444.864532,502.316376 479.816589,502.153107 
	C480.453644,502.150146 481.091614,502.340454 483.106415,502.656891 
	C481.520569,505.456909 480.241211,507.715759 478.639221,510.095703 
z"/>
<path fill="#F206FF" opacity="1.000000" stroke="none" 
	d="
M618.051941,346.225220 
	C613.396362,348.208191 609.267395,348.388367 606.119141,344.297852 
	C603.867676,341.372467 604.867615,335.290100 607.852661,331.868042 
	C610.906006,328.367676 616.573669,326.984222 620.487488,328.783875 
	C624.414490,330.589630 625.898865,333.985107 623.883667,338.750305 
	C622.732544,341.472321 620.260071,343.635559 618.051941,346.225220 
z"/>
<path fill="#FEFDFF" opacity="1.000000" stroke="none" 
	d="
M490.000458,715.000000 
	C490.000458,707.397400 490.000458,700.294739 490.000458,692.979675 
	C501.034882,692.605042 512.023315,691.284424 518.612061,702.061890 
	C523.944763,710.784790 523.934814,720.415833 518.694519,729.090637 
	C512.222168,739.804993 501.097443,738.541748 490.000458,738.646179 
	C490.000458,730.668457 490.000458,723.084229 490.000458,715.000000 
z"/>
<path fill="#FCF7FD" opacity="1.000000" stroke="none" 
	d="
M729.732544,722.252808 
	C730.100525,723.584473 730.341919,724.540039 730.678223,725.870850 
	C724.468018,725.870850 718.436584,725.870850 711.717773,725.870850 
	C714.793823,716.617310 717.787231,707.612244 721.174622,697.421875 
	C724.268921,706.396545 726.937439,714.136597 729.732544,722.252808 
z"/>
<path fill="#FCF7FD" opacity="1.000000" stroke="none" 
	d="
M573.462769,720.493896 
	C576.028381,712.930237 578.423157,705.737976 581.215698,697.351257 
	C584.694702,707.642029 587.739929,716.649841 590.879700,725.937134 
	C584.364441,725.937134 578.291504,725.937134 571.875183,725.937134 
	C572.413269,724.010986 572.852600,722.438110 573.462769,720.493896 
z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
